import styled from 'styled-components'

import media from '../utils/media'

import ShieldIcon from '../assets/svg/shield.svg'

const Container = styled.section`
  background-color: var(--color-background-mint);
  border-radius: var(--border-radius-large);
  text-align: center;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 30px 35px 15px;
  width: 100%;

  ${media.sm`
    text-align: left;

    flex-wrap: nowrap;
    padding-right: 70px;
  `}
`

const Icon = styled.div`
  flex: none;
  margin: 0 auto;

  ${media.sm`
    margin-right: 0;
    margin-left: 0;
  `}
`

const Content = styled.div`
  flex: 1 1 auto;

  ${media.sm`
    margin-left: 20px;
  `}
`

const Title = styled.h2`
  font-size: 150%;

  margin: 0 0 10px;
`

const Info = styled.p`
  line-height: 160%;

  margin-bottom: 1.2em;
`

const title = 'Money Back Guarantee'

const PricingMoneyBackGuarantee = () => {
  return (
    <Container>
      <Icon>
        <ShieldIcon alt={title} />
      </Icon>

      <Content>
        <Title>{title}</Title>

        <Info>
          If you’re not satisfied with VisualCV for any reason within your first 30 days, simply email us and we’ll
          refund your money. No strings attached.
        </Info>
      </Content>
    </Container>
  )
}

export default PricingMoneyBackGuarantee
