import styled from 'styled-components'
import Wrapper from './Wrapper'

const PageBannerContent = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ $lightStyling }) => ($lightStyling ? 'var(--color-background-light)' : 'unset')};
`

const PageBannerContainer = ({ children, ...rest }) => (
  <PageBannerContent {...rest}>
    <Wrapper>{children}</Wrapper>
  </PageBannerContent>
)

export default PageBannerContainer
