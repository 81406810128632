import styled from 'styled-components'

import checkmark from '../assets/svg/checkmark.svg'

import media from '../utils/media'

import PricingPlan from './PricingPlan'
import PricingPlanCTA from './PricingPlanCTA'

const Plan = styled(PricingPlan)`
  background: var(--color-background-white);
  box-shadow: var(--box-shadow-primary-small);

  order: 1;
  padding-top: 35px;

  &:hover {
    box-shadow: var(--box-shadow-primary-medium-bottom-with-glow);
  }

  ${media.sm`
    padding-top: 85px;
  `}

  ${media.md`
    margin-right: 25px;
    order: 0;
  `}
`

const Content = styled.div`
  padding: 0 7%;

  ${media.sm`
    padding-left: 13%;
    padding-right: 13%;
  `}
`

const Title = styled.h2`
  font-size: 200%;

  margin-bottom: 0;
  margin-left: 20px;
  margin-right: 20px;
`

const Amount = styled.p`
  font-size: 225%;
  font-weight: 500;
  line-height: 100%;

  margin: 10px 0 0;

  ${media.sm`
    font-size: 300%;
  `}
`

const Incentive = styled.p`
  line-height: 140%;

  margin: 20px 0 0;
`

const Features = styled.ul`
  text-align: left;

  margin: 20px 0 60px 0;
`

const Feature = styled.li`
  list-style: none;

  display: flex;
  align-items: flex-start;

  margin-bottom: 0;
  padding: 12px 0;

  p {
    line-height: 140%;
  }
`

const Checkmark = styled(checkmark)`
  fill: #20d075;

  margin: 2px 18px 0 0;
  width: 16px;
`

const PricingPlanFree = ({ ctaLink }) => {
  return (
    <Plan>
      <Content>
        <Title>Free Account</Title>

        <Amount>$0</Amount>

        <Incentive>Always free, no hidden fees.</Incentive>

        <Features>
          <Feature>
            <Checkmark />
            <p>Test Drive All Resume Templates</p>
          </Feature>

          <Feature>
            <Checkmark />
            <p>Create Your 1st Resume</p>
          </Feature>

          <Feature>
            <Checkmark />
            <p>Preview your Resume’s Formatting</p>
          </Feature>

          <Feature>
            <Checkmark />
            <p>Career Journal Trial Access</p>
          </Feature>
        </Features>

        <PricingPlanCTA
          $secondary
          to={ctaLink}>
          Sign Up Free
        </PricingPlanCTA>
      </Content>
    </Plan>
  )
}

export default PricingPlanFree
