import { useEffect } from 'react'
import styled from 'styled-components'

import media from '../utils/media'

import polygonOne from '../assets/svg/polygon-one.svg'
import polygonTwo from '../assets/svg/polygon-two.svg'
import polygonThree from '../assets/svg/polygon-three.svg'
import polygonFour from '../assets/svg/polygon-four.svg'
import polygonFive from '../assets/svg/polygon-five.svg'
import polygonSix from '../assets/svg/polygon-six.svg'

const Container = styled.aside`
  background-color: var(--color-background-pink);
  overflow: hidden;

  height: 600px;
  margin: 0 auto;
  position: relative;
  width: 100%;
`

const offsets = {
  one: 0,
  two: 120,
  three: 350,
  four: 0,
  five: -12,
  six: 210,
}

const PolygonOne = styled(polygonOne)`
  display: none;
  max-width: 80px;
  position: absolute;
  left: 220px;
  top: ${offsets.one}px;

  ${media.sm`
    display: block;
  `}
`

const PolygonTwo = styled(polygonTwo)`
  display: none;
  max-width: 311px;
  position: absolute;
  left: -52px;
  top: ${offsets.two}px;

  ${media.lg`
    display: block;
  `}
`

const PolygonThree = styled(polygonThree)`
  display: none;
  max-width: 78px;
  position: absolute;
  left: 180px;
  top: ${offsets.three}px;

  ${media.sm`
    display: block;
  `}
`

const PolygonFour = styled(polygonFour)`
  max-width: 174px;
  position: absolute;
  bottom: ${offsets.four}px;
  left: 0;

  ${media.lg`
    display: block;
    left: 80px;
  `}
`

const PolygonFive = styled(polygonFive)`
  display: none;
  max-width: 126px;
  position: absolute;
  right: 60px;
  top: ${offsets.five}px;

  ${media.md`
    display: block;
  `}
`

const PolygonSix = styled(polygonSix)`
  max-width: 163px;
  position: absolute;
  right: -33px;
  top: ${offsets.six}px;
`

const PricingBackgroundTop = () => {
  const addScrollingListener = () => {
    if (typeof window === 'undefined') {
      return
    }

    const one = document.querySelector('.polygon-one')
    const two = document.querySelector('.polygon-two')
    const four = document.querySelector('.polygon-four')
    const five = document.querySelector('.polygon-five')
    const six = document.querySelector('.polygon-six')

    const repositionPolygons = (scrollY) => {
      const offsetOne = offsets.one + scrollY * 1.15
      const offsetTwo = offsets.two + scrollY * 0.4
      const offsetFour = offsets.four - scrollY * 0.1
      const offsetFive = offsets.five + scrollY * 0.4
      const offsetSix = offsets.six - scrollY * 0.2

      one.style.top = `${offsetOne}px`
      two.style.top = `${offsetTwo}px`
      four.style.bottom = `${offsetFour}px`
      five.style.top = `${offsetFive}px`
      six.style.top = `${offsetSix}px`
    }

    let ticking = false

    const animatePolygons = () => {
      const scrollY = window.scrollY || window.pageYOffset

      if (!ticking) {
        window.requestAnimationFrame(() => {
          repositionPolygons(scrollY)
          ticking = false
        })

        ticking = true
      }
    }

    window.addEventListener('scroll', animatePolygons, false)

    const removeScrollingListener = () => {
      window.removeEventListener('scroll', animatePolygons)
    }

    return removeScrollingListener
  }

  useEffect(addScrollingListener, [])

  return (
    <Container>
      <PolygonOne
        className="polygon-one"
        alt=""
      />
      <PolygonTwo
        className="polygon-two"
        alt=""
      />
      <PolygonThree
        className="polygon-three"
        alt=""
      />
      <PolygonFour
        className="polygon-four"
        alt=""
      />
      <PolygonFive
        className="polygon-five"
        alt=""
      />
      <PolygonSix
        className="polygon-six"
        alt=""
      />
    </Container>
  )
}

export default PricingBackgroundTop
