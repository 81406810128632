import styled from 'styled-components'

import media from '../utils/media'

import checkmark from '../assets/svg/checkmark.svg'

import PricingPlan from './PricingPlan'
import PricingPlanCTA from './PricingPlanCTA'

const Plan = styled(PricingPlan)`
  background: var(--color-primary);
  box-shadow: var(--box-shadow-primary-3phase-small);

  order: 0;

  &:hover {
    box-shadow: var(--box-shadow-primary-3phase-medium);
  }

  ${media.md`
    order: 1;
  `}
`

const Content = styled.div`
  padding: 0 7%;

  ${media.sm`
    padding-left: 13%;
    padding-right: 13%;
  `}
`

const Toggle = styled.nav`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--border-radius-large);
  font-weight: 500;

  height: 40px;
  position: relative;
  margin: -53px auto 13px;
  width: 250px;
`

const PlanIndicator = styled.em`
  background: var(--color-background-white);
  border-radius: var(--border-radius-large);
  box-shadow: var(--box-shadow-black-small);
  font-variant: normal;
  transition: left 0.25s ease-in-out;

  height: 80%;
  position: absolute;
  left: ${({ $left }) => ($left ? '1.5%' : '50%')};
  top: 10%;
  width: 48.5%;
`

const PlanMonthly = styled.label`
  color: ${({ $isActive }) => ($isActive ? 'var(--color-typography-dark)' : 'var(--color-typography-on-primary)')};
  cursor: pointer;
  font-size: 81.25%;
  transition: color 0.25s;

  position: absolute;
  left: 25%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const PlanQuarterly = styled.label`
  color: ${({ $isActive }) => ($isActive ? 'var(--color-typography-dark)' : 'var(--color-typography-on-primary)')};
  cursor: pointer;
  font-size: 81.25%;
  transition: color 0.25s;

  position: absolute;
  right: 25%;
  top: 50%;
  transform: translate(50%, -50%);
`

const PlanDiscount = styled.span`
  font-weight: 500;
  color: ${({ $active }) => ($active ? 'var(--color-typography-primary)' : 'var(--color-typography-on-primary)')};
  transition: color 0.25s;

  margin-left: 6px;
`

const Title = styled.h2`
  color: var(--color-typography-on-primary);
  font-size: 200%;

  margin-bottom: 0;
  margin-left: 20px;
  margin-right: 20px;
`

const Amount = styled.p`
  color: var(--color-typography-on-primary);
  font-size: 225%;
  font-weight: 500;
  line-height: 100%;

  margin: 10px 0 0;

  ${media.sm`
    font-size: 300%;
  `}
`

const Currency = styled.span`
  color: var(--color-typography-on-primary);
  font-size: 33.33%;
  line-height: 100%;
  opacity: 0.6;

  margin-left: 7px;
`

const Incentive = styled.p`
  color: var(--color-typography-on-primary);
  opacity: 0.6;
  margin: 20px 0 0;
`

const Features = styled.ul`
  text-align: left;

  margin: 20px 0 60px 0;
`

const Feature = styled.li`
  list-style: none;

  display: flex;
  align-items: flex-start;

  margin-bottom: 0;
  padding: 12px 0;

  p {
    color: var(--color-typography-on-primary);
  }
`

const Checkmark = styled(checkmark)`
  fill: var(--color-typography-on-primary);

  margin: 2px 18px 0 0;
  width: 16px;
`

const PricingPlanPro = ({ ctaLink, plan, monthlyPlan, quarterlyPlan, switchToMonthly, switchToQuarterly, amount }) => {
  const title = plan === quarterlyPlan ? 'Pro Quarterly' : 'Pro Monthly'
  const period = plan === quarterlyPlan ? 'quarterly' : 'monthly'

  const quarterlyDiscount = 36

  return (
    <Plan>
      <Content>
        <Toggle>
          <PlanIndicator $left={plan === monthlyPlan} />

          <PlanMonthly
            $isActive={plan === monthlyPlan}
            onClick={switchToMonthly}>
            Monthly
          </PlanMonthly>

          <PlanQuarterly
            $isActive={plan === quarterlyPlan}
            onClick={switchToQuarterly}>
            Quarterly
            <PlanDiscount $active={plan === quarterlyPlan}>-{quarterlyDiscount}%</PlanDiscount>
          </PlanQuarterly>
        </Toggle>

        <Title>{title}</Title>

        <Amount>
          ${amount}
          <Currency>USD</Currency>
        </Amount>

        <Incentive>Per Month (Billed {period})</Incentive>

        <Features>
          <Feature>
            <Checkmark />
            <p>30+ Professional Resume Templates</p>
          </Feature>

          <Feature>
            <Checkmark />
            <p>Create Unlimited Resumes</p>
          </Feature>

          <Feature>
            <Checkmark />
            <p>Download Unlimited PDFs</p>
          </Feature>

          <Feature>
            <Checkmark />
            <p>Career Journal Pro Access</p>
          </Feature>
        </Features>

        <PricingPlanCTA
          $secondary
          to={ctaLink}>
          Get Started With Pro
        </PricingPlanCTA>
      </Content>
    </Plan>
  )
}

export default PricingPlanPro
