import styled from 'styled-components'

import media from '../utils/media'

import CTA from './CTA'

const Container = styled.aside`
  background-color: ${({ $free }) =>
    $free ? 'var(--color-background-light-blue)' : 'var(--color-background-lighter-primary)'};
  border-radius: var(--border-radius-large);

  flex: 1 1 auto;
  margin: 0 auto 35px;
  max-width: 455px;
  order: ${({ $free }) => ($free ? 1 : 0)};
  width: 100%;

  ${media.md`
    margin-right: ${({ $free }) => ($free ? 25 : 0)}px;
    margin-left: 0;
    order: ${({ $free }) => ($free ? 0 : 1)};
  `}
`

const ContainerScrolling = styled.div``

const Header = styled.header`
  position: sticky;
  top: calc(var(--nav-header-height) + 1px);
`

const HeaderTitle = styled.h3`
  font-size: 150%;
  font-weight: 500;

  margin: 0;

  ${media.sm`
    font-size: 200%;
  `}
`

const HeaderAmount = styled.h4`
  font-size: 150%;
  font-weight: 500;

  margin: 0;
`

const Content = styled.div`
  padding: 0 7%;

  ${media.sm`
    padding-left: 14%;
    padding-right: 14%;
  `}
`

const Footer = styled.footer`
  background-color: ${({ $free }) =>
    $free ? 'var(--color-background-light-blue)' : 'var(--color-background-lighter-primary)'};
  border-bottom-left-radius: var(--border-radius-large);
  border-bottom-right-radius: var(--border-radius-large);

  padding: 30px 7%;

  ${media.sm`
    padding-left: 14%;
    padding-right: 14%;
  `}
`

const HeaderContent = styled.div`
  background-color: ${({ $free }) =>
    $free ? 'var(--color-background-light-blue)' : 'var(--color-background-lighter-primary)'};
  border-top-left-radius: var(--border-radius-large);
  border-top-right-radius: var(--border-radius-large);
  box-shadow: ${({ $free }) => ($free ? 'var(--box-shadow-lighter-primary-small)' : 'var(--box-shadow-primary-small)')};

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 32px 7% 24px;

  ${media.sm`
    padding-left: 14%;
    padding-right: 14%;
  `}
`

const Features = styled.ul`
  text-align: left;

  margin: 0;
  width: 100%;
`

const Feature = styled.li`
  border-bottom: 1px solid ${({ $free }) => ($free ? 'var(--color-lighter-grey)' : 'var(--color-light-primary)')};
  list-style: none;

  margin-bottom: 0;
  padding: 25px 0;

  &:last-child {
    border-bottom: none;
  }
`

const Title = styled.h5`
  color: ${({ $available }) => ($available ? 'var(--color-typography-dark)' : 'var(--color-typography-light-grey)')};
  font-size: 100%;
  font-weight: 500;
  line-height: 200%;

  margin: 0;
`

const Description = styled.p`
  color: ${({ $available }) => ($available ? 'var(--color-typography-dark)' : 'var(--color-typography-light-grey)')};
  font-size: 87.5%;
  line-height: 200%;
`

const CTAButton = styled(CTA)`
  background-color: ${({ $free }) => ($free ? 'var(--color-background-white)' : 'var(--color-background-primary)')};
  color: ${({ $free }) => ($free ? 'var(--color-typography-dark)' : 'var(--color-typography-on-primary)')};

  margin: 0 -5%;
  width: 100%;

  ${media.sm`
    margin-left: -10%;
    margin-right: -10%;
    width: 120%;
  `}
`

// className is necessary for passing styles from the parent
const PricingFeaturesList = ({ free, title, amount, features, className, cta, to }) => {
  return (
    <Container
      $free={free}
      className={className}>
      <ContainerScrolling>
        <Header>
          <HeaderContent $free={free}>
            <HeaderTitle>{title}</HeaderTitle>
            <HeaderAmount>{amount}</HeaderAmount>
          </HeaderContent>
        </Header>

        <Content>
          <Features>
            {features.map((feature) => (
              <Feature
                $free={free}
                key={feature.title}>
                <Title $available={!feature.unavailable}>{feature.title}</Title>
                <Description $available={!feature.unavailable}>{feature.description}</Description>
              </Feature>
            ))}
          </Features>
        </Content>
      </ContainerScrolling>

      <Footer $free={free}>
        <CTAButton
          $free={free}
          to={to}>
          {cta}
        </CTAButton>
      </Footer>
    </Container>
  )
}

export default PricingFeaturesList
