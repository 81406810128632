import styled from 'styled-components'

import media from '../utils/media'

import PricingPlanFree from './PricingPlanFree'
import PricingPlanPro from './PricingPlanPro'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  width: 100%;

  ${media.md`
    flex-wrap: nowrap;
  `}
`

const PricingPlans = ({
  signupLink,
  checkoutLink,
  plan,
  monthlyPlan,
  quarterlyPlan,
  switchToMonthly,
  switchToQuarterly,
  amount,
}) => {
  return (
    <Container>
      <PricingPlanFree ctaLink={signupLink} />

      <PricingPlanPro
        ctaLink={checkoutLink}
        plan={plan}
        monthlyPlan={monthlyPlan}
        quarterlyPlan={quarterlyPlan}
        switchToMonthly={switchToMonthly}
        switchToQuarterly={switchToQuarterly}
        amount={amount}
      />
    </Container>
  )
}

export default PricingPlans
