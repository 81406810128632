import styled from 'styled-components'

import media from '../utils/media'

import PricingFeaturesFree from './PricingFeaturesFree'
import PricingFeaturesPro from './PricingFeaturesPro'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  width: 100%;

  ${media.md`
    flex-wrap: nowrap;
  `}
`

const PricingFeatures = ({ signupLink, checkoutLink, amount }) => {
  return (
    <Container>
      <PricingFeaturesFree ctaLink={signupLink} />

      <PricingFeaturesPro
        amount={amount}
        ctaLink={checkoutLink}
      />
    </Container>
  )
}

export default PricingFeatures
