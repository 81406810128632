import styled from 'styled-components'

import media from '../utils/media'

const PricingPlan = styled.aside`
  border-radius: var(--border-radius-large);
  transition: all 250ms ease 0s;

  flex: 1 1 auto;
  margin: 0 auto 30px;
  max-width: 455px;
  padding-top: 85px;
  padding-bottom: 35px;
  width: 100%;

  &:hover {
    transform: translateY(-4px);
  }

  ${media.md`
    margin-right: 0;
    margin-left: 0;
  `}
`

export default PricingPlan
