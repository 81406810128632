import PricingFeaturesList from './PricingFeaturesList'

const features = [
  {
    title: '30+ Professionally Designed Templates',
    description: 'Get noticed with premium designs',
  },
  {
    title: 'Create Unlimited Resumes',
    description: 'Customize for every career opportunity',
  },
  {
    title: 'Download Unlimited PDFs',
    description: 'No restrictions or extra fees',
  },
  {
    title: 'Career Journal Pro Access',
    description: 'Track your biggest career achievements',
  },
  {
    title: 'Personal Professional Website',
    description: 'Showcase your digital portfolio',
  },
  {
    title: 'Shareable Resume Links',
    description: 'Share online quickly and easily',
  },
  {
    title: 'ATS-Friendly Template',
    description: 'Beat the Applicant Tracking System',
  },
  {
    title: 'Cover Letter Builder',
    description: 'Make your best first impression',
  },
  {
    title: 'Resume Performance Analytics',
    description: 'Track views and downloads on your CV',
  },
]

const PricingFeaturesPro = ({ amount, ctaLink }) => (
  <PricingFeaturesList
    title="Pro"
    amount={`$${amount}/m`}
    features={features}
    cta="Get Pro Now"
    to={ctaLink}
  />
)

export default PricingFeaturesPro
