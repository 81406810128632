import styled from 'styled-components'

import media from '../utils/media'

const PricingHeading = styled.h2`
  font-size: 175%;
  line-height: 120%;

  margin: 120px 0 50px;
  width: 100%;

  ${media.sm`
    font-size: 200%;
  `}

  ${media.md`
    font-size: 250%;
  `}
`

export default PricingHeading
