import { getImage } from 'gatsby-plugin-image'
import { formatFullName } from '../utils/locales'

import TestimonialCarousel from './TestimonialCarousel'

const PricingSuccessStories = ({ edges }) => {
  const testimonials = edges.map((edge) => {
    const {
      node,
      node: { first_name, last_name, quote },
    } = edge

    const author = formatFullName(first_name, last_name)
    const photo = getImage(node.photo.localFile)

    return {
      quote: quote.quote,
      author,
      photo,
    }
  })

  return <TestimonialCarousel slides={testimonials} />
}

export default PricingSuccessStories
