import { useState } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import media from '../utils/media'
import { createAppUrl } from '../utils/locales'
import { useIntl } from '../utils/IntlContext'
import useSignUpLink from '../hooks/queries/useSignUpLink'

import chevronDown from '../assets/svg/chevron-down.svg'

import LayoutWrapper from '../components/LayoutWrapper'
import Link from '../components/Link'
import PageSectionBannerTextBackground from '../components/PageSectionBannerTextBackground'
import PricingBackgroundTop from '../components/PricingBackgroundTop'
import PricingCompanies from '../components/PricingCompanies'
import PricingFaq from '../components/PricingFaq'
import PricingFeatures from '../components/PricingFeatures'
import PricingHeading from '../components/PricingHeading'
import PricingMoneyBackGuarantee from '../components/PricingMoneyBackGuarantee'
import PricingPlans from '../components/PricingPlans'
import PricingSuccessStories from '../components/PricingSuccessStories'
import GatsbyHead from '../components/GatsbyHead'

export const query = graphql`
  query Pricing($contentful_id: String!, $locale: String!) {
    contentfulReactPage(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      id
      contentful_id
      slug
      set_as_homepage
      node_locale
      languages
      meta_title
      meta_description
    }
    allContentfulSuccessStory(
      filter: {
        contentful_id: {
          in: ["2Mn4opd0b6QUeUceeImQkY", "6SfNaXOpPySmE88kSEsWeG", "387YXCAXKEE2EY0qISi0yo", "48GX9XiO764UoQ8uOgaMiI"]
        }
        node_locale: { eq: $locale }
      }
      sort: { fields: weight, order: DESC }
    ) {
      edges {
        node {
          first_name
          last_name
          quote {
            quote
          }
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 360)
              }
            }
          }
        }
      }
    }
    allContentfulComponentFaq(
      filter: { contentful_id: { eq: "6nfcBY9rIMiWi2QgLNo55c" }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          ...ComponentFaq
        }
      }
    }
    pricingShapesBanner: contentfulBannerTextBackground(contentful_id: { eq: "1ZQt31davVnHVncq9WiUks" }) {
      ...BannerTextBackground
    }
  }
`

const PricingPage = styled.main`
  text-align: center;

  padding-top: var(--nav-header-height);
  padding-bottom: 50px;
  position: relative;
`

const Container = styled.article`
  margin: -540px auto 0;
  max-width: var(--max-width);
  padding: 0 var(--spacing-md);
  position: relative;
`

const Narrow = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 35px;
  max-width: 940px;
`

const Title = styled.h1`
  font-size: 200%;
  line-height: 120%;

  margin: 0.2em 0 0.5em;
  width: 100%;

  ${media.sm`
    font-size: 250%;
  `}

  ${media.md`
    font-size: 300%;
  `}
`

const Subtitle = styled.p`
  margin-bottom: 35px;
  width: 100%;
`

const Info = styled.p`
  color: var(--color-typography-dark);
  font-size: 87.5%;
  font-weight: 600;

  margin: 0 0 0.8625rem;
  width: 100%;
`

const Chevron = styled(Link)`
  background-color: #c2d1d9;
  border-radius: 100%;
  transition: background-color 0.2s;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 50px;
  margin: 0 auto 65px;
  width: 50px;

  &:hover {
    background-color: #cedee6;
  }
`

const ChevronDown = styled(chevronDown)`
  opacity: 0.8;

  width: 21px;
`

const Pricing = ({
  pageContext: { translatedPages },
  data: {
    allContentfulSuccessStory: { edges },
    pricingShapesBanner,
    allContentfulComponentFaq: {
      edges: [
        {
          node: { title, faqs },
        },
      ],
    },
  },
}) => {
  const monthlyPlan = 'monthly_25'
  const quarterlyPlan = 'quarterly_48'

  const [plan, setPlan] = useState(quarterlyPlan)

  const monthlyAmount = 25
  const quarterlyMonthlyAmount = 16

  const amount = plan === quarterlyPlan ? quarterlyMonthlyAmount : monthlyAmount

  const { locale } = useIntl()
  const signupLink = useSignUpLink(locale)

  const switchToMonthly = () => setPlan(monthlyPlan)
  const switchToQuarterly = () => setPlan(quarterlyPlan)

  const checkoutLink = createAppUrl(`/checkout?plan=${plan}`, locale)

  return (
    <LayoutWrapper translatedPages={translatedPages}>
      <PricingPage>
        <PricingBackgroundTop />

        <Container>
          <Narrow id="top">
            <Title>Grow your career with the resume builder trusted by 3+ million people.</Title>

            <Subtitle>Professional resume templates to help you land your dream job.</Subtitle>

            <PricingPlans
              signupLink={signupLink}
              checkoutLink={checkoutLink}
              plan={plan}
              monthlyPlan={monthlyPlan}
              quarterlyPlan={quarterlyPlan}
              switchToMonthly={switchToMonthly}
              switchToQuarterly={switchToQuarterly}
              amount={amount}
            />
          </Narrow>

          <Info>Discover All Pro Features</Info>

          <Chevron to="#features">
            <ChevronDown />
          </Chevron>

          <PricingMoneyBackGuarantee />

          <Narrow>
            <PricingHeading>
              VisualCV Pro members are getting hired at the world’s best companies. You can too.
            </PricingHeading>
          </Narrow>

          <PricingCompanies />

          <Narrow id="features">
            <PricingHeading>What You Get with VisualCV</PricingHeading>

            <PricingFeatures
              signupLink={signupLink}
              checkoutLink={checkoutLink}
              amount={amount}
            />
          </Narrow>

          <PricingHeading>What Members Love About VisualCV Pro</PricingHeading>

          <PricingSuccessStories edges={edges} />

          <PricingFaq
            title={title}
            faqs={faqs}
          />

          <PageSectionBannerTextBackground banner={pricingShapesBanner} />
        </Container>
      </PricingPage>
    </LayoutWrapper>
  )
}

/** @type {import('gatsby').HeadFC} */
export const Head = ({
  pageContext: { canonicalUrl, translatedPages },
  data: {
    contentfulReactPage: { meta_title, meta_description },
  },
}) => {
  return (
    <GatsbyHead
      pageTitle={meta_title}
      metaDescription={meta_description}
      canonicalUrl={canonicalUrl}
      translatedPages={translatedPages}
    />
  )
}

export default Pricing
