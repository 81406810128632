import styled from 'styled-components'

import PageSectionFAQ from './PageSectionFAQ'

const Container = styled.div`
  margin: 70px auto 100px;
`

const PricingFaq = ({ title, faqs }) => {
  return (
    <Container>
      <PageSectionFAQ
        title={title}
        faqs={faqs}
      />
    </Container>
  )
}

export default PricingFaq
