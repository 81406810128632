import styled, { css } from 'styled-components'
import Polygons from '../assets/img/polygons.svg?url'
import media from '../utils/media'
import CTA from './CTA'
import PageBannerContainer from './PageBannerContainer'
import Wrapper from './Wrapper'

const shapesStyles = css`
  max-width: 1126px;
`

const shapesBackground = css`
  background-image: url('${Polygons}');
  background-position: 25% 0;
  background-size: 350%;

  ${media.sm`
    background-position: 26% -65px;
    background-size: 250%;
  `}

  ${media.md`
    background-position: center;
    background-size: 150%;
  `}

  ${media.lg`
    background-position: 0 0;
    background-size: auto;
    border-radius: var(--border-radius-large);
  `}
`

const Heading = styled.span`
  font-weight: 600;
  font-size: 150%;
  display: block;
  padding: 0 25px;
  margin: ${({ $description }) => ($description ? 'unset' : '0 0 20px')};

  ${media.sm`
    font-size: 175%;
  `}

  ${media.md`
    font-size: 200%;
  `}
`

const Container = styled(Wrapper)`
  padding: 75px 30px 50px 30px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  min-height: 288px;
  text-align: center;
`

const SectionBannerContainer = styled(PageBannerContainer)`
  min-height: 288px;
  margin: 0 auto;
  overflow: hidden;
  ${({ $blockSize }) => ($blockSize ? shapesStyles : '')}
  ${({ $useShapesBackground }) => ($useShapesBackground ? shapesBackground : '')}
`

const StyledCTA = styled(CTA)`
  && {
    margin: 0 auto;
  }
`

const PageSectionBannerTextBackground = ({ banner, altCta, altTitle }) => {
  const { title, cta, bg_desc, useShapesBackground, blockSize } = banner
  const bannerTitle = altTitle || title
  const bannerCta = altCta || cta
  const description = bg_desc?.description

  return (
    bannerTitle && (
      <SectionBannerContainer
        $blockSize={blockSize}
        $useShapesBackground={useShapesBackground}
        $lightStyling={!useShapesBackground}>
        <Container>
          <Heading $description={Boolean(description)}>{bannerTitle}</Heading>
          {description && <p>{description}</p>}
          <StyledCTA
            $primary
            to={bannerCta}>
            {bannerCta.text.value}
          </StyledCTA>
        </Container>
      </SectionBannerContainer>
    )
  )
}

export default PageSectionBannerTextBackground
