import styled, { css } from 'styled-components'

import media from '../utils/media'

import CTA from './CTA'

const PricingPlanCTA = styled(CTA)`
  margin: 0 -5%;
  width: 100%;

  ${({ $secondary }) =>
    $secondary &&
    css`
      background-color: var(--color-background-white);
    `}

  ${media.sm`
    margin-left: -10%;
    margin-right: -10%;
    width: 120%;
  `}
`

export default PricingPlanCTA
