import PricingFeaturesList from './PricingFeaturesList'

const features = [
  {
    title: 'Test Drive All Resume Templates',
    description: 'Try from 30+ professional resume templates',
  },
  {
    title: 'Create Your First Resume',
    description: 'The first step towards your next job',
  },
  {
    title: 'Download Unlimited PDFs',
    description: 'No restrictions or extra fees',
    unavailable: true,
  },
  {
    title: 'Career Journal Pro Access',
    description: 'Track your biggest career achievements',
    unavailable: true,
  },
  {
    title: 'Personal Professional Website',
    description: 'Showcase your digital portfolio',
    unavailable: true,
  },
  {
    title: 'Shareable Resume Links',
    description: 'Share online quickly and easily',
    unavailable: true,
  },
  {
    title: 'ATS-Friendly Template',
    description: 'Beat the Applicant Tracking System',
    unavailable: true,
  },
  {
    title: 'Cover Letter Builder',
    description: 'Make your best first impression',
    unavailable: true,
  },
  {
    title: 'Resume Performance Analytics',
    description: 'Track views and downloads on your CV',
    unavailable: true,
  },
]

const PricingFeaturesFree = ({ ctaLink }) => {
  return (
    <PricingFeaturesList
      free
      title="Free"
      amount="$0"
      features={features}
      cta="Sign Up Free"
      to={ctaLink}
    />
  )
}

export default PricingFeaturesFree
