import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import media from '../utils/media'

const Container = styled.section`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;

  margin-top: -35px;

  ${media.sm`
    flex-wrap: no-wrap;
  `}
`

const Logo = styled.div`
  transform: scale(0.75);

  margin: 0 20px 20px;
  width: 100%;

  ${media.md`
    transform: none;

    margin: 0 35px 30px;
  `}
`

const Apple = styled(Logo)`
  max-width: 128px;
`

const Microsoft = styled(Logo)`
  max-width: 198px;
`

const CocaCola = styled(Logo)`
  max-width: 118px;
`

const Toyota = styled(Logo)`
  max-width: 178px;
`

const Visa = styled(Logo)`
  max-width: 78px;
`

const PricingCompanies = () => {
  return (
    <Container>
      <Apple>
        <StaticImage
          src="../assets/img/logos/apple.png"
          layout="fixed"
          width={128}
          alt="Apple"
        />
      </Apple>
      <Microsoft>
        <StaticImage
          src="../assets/img/logos/microsoft.png"
          layout="fixed"
          width={198}
          alt="Microsoft"
        />
      </Microsoft>
      <CocaCola>
        <StaticImage
          src="../assets/img/logos/coca_cola.png"
          layout="fixed"
          width={118}
          alt="Coca-Cola"
        />
      </CocaCola>
      <Toyota>
        <StaticImage
          src="../assets/img/logos/toyota.png"
          layout="fixed"
          width={178}
          alt="Toyota"
        />
      </Toyota>
      <Visa>
        <StaticImage
          src="../assets/img/logos/visa.png"
          layout="fixed"
          width={78}
          alt="Visa"
        />
      </Visa>
    </Container>
  )
}

export default PricingCompanies
